import React, { Component ,Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, Link} from "react-router-dom";
import Inicio from './components/Sections/inicio/inicio';
import Contactoareascomunes from './components/Sections/contacto/contacto';
import Footer from './components/Sections/footer/footer';
import Navbar from './components/navbar/navbar';
import SeccionesSlider from './components/Sections/atributos/atributos'
import "./components/menu/menu.scss"
import Mainpage from './components/paginas/inicio/inicio/Mainpage';
import Contactoinicio from './components/paginas/inicio/contacto/contacto-two'
import Atributostwo from './components/paginas/inicio/atributo-two.js/atributo-two';
import Respaldo from './components/paginas/respaldo/respaldo';
import Contactopromociones from './components/paginas/promociones/contactopro';
import {inicio, promociones,recorridoVirtual, obra,departamentos} from "./properties/properties"
import Virtual from './components/paginas/virtual/virtual';
import Obra from './components/paginas/avance-de-obra/obra';
import Slidervirtual from './components/paginas/virtual/slidervirtual/slidervirtual';
import Departamentos from './components/Sections/departamentos/departamentos';
import Sliderobra from './components/paginas/avance-de-obra/slider-avance/slider-obra';
import { secciones } from "./components/Sections/atributos/atributosSlider" 
import Brochure from './components/paginas/brochure/brochure';
import Paginadeinicio from 'Paginas/inicio/pagina-principal/pagina-inicio';
import Popup from './components/paginas/popup/popup';
import Reclamacion from 'Paginas/reclamacion/reclamacion';
import Whatsapp from './components/whatsapp/whatsapp'
import {Banner} from './components/banner/banner'
import Electrodomesticos from './components/electrodomesticos/electrodomesticos';
import SectionContact from './components/paginas/inicio/section-contacto/contacto';
import Phone from 'components/phone/phone';
import Contactanos from 'components/paginas/contactanos/contactanos';
import Loader from './components/loader/loader';
import Notice from 'components/notice/notice';
import CreaMomentos from 'components/paginas/creamomentos/creamomentos';
import Error404 from './components/paginas/404/error404';
import { Thanks } from 'Paginas/thanks/thanks';
import { FloatForm } from './components/floatform/floatform';
export default class Routes extends Component {
    componentDidMount(){
        let time = setInterval(() => {
            window.scrollTo(0,0)
            clearInterval(time)
        }, 2000);
    }
    render() {
        return (
        <div className="Main-pages">
            <Router>
            <Navbar/>
            <Notice></Notice>
            { process.env.NODE_ENV == "producction" ? null : <Loader></Loader>}
            <div className="">
                {/* <Cargador></Cargador> */}
                <Switch>
                    <Route exact path="/">
                        {/* <Popup></Popup> */}
                        <FloatForm></FloatForm>
                        {/* <Phone></Phone> */}
                        <Whatsapp></Whatsapp>
                        <Paginadeinicio></Paginadeinicio>
                        <Atributostwo/>
                        <Contactoinicio/>
                        <Respaldo/>
                        <SeccionesSlider slider={secciones}/>
                        <SectionContact></SectionContact>
                        <Banner></Banner>
                    </Route>
                    <Route exact path="/libro-de-reclamaciones">
                        <Reclamacion></Reclamacion>
                    </Route>
                    <Route exact path="/areas-comunes">
                       
                        <Whatsapp></Whatsapp>
                        <Inicio/>
                        <SeccionesSlider slider={secciones}/>
                        <Contactoareascomunes/>
                    </Route>
                    <Route exact path="/promociones">
                        {/* <Phone></Phone> */}
                        <Whatsapp></Whatsapp>
                        <FloatForm></FloatForm>
                        <Mainpage inicio={promociones}/>
                        <SectionContact></SectionContact>
                    </Route>
                    <Route exact path="/recorrido-virtual">
                        {/* <Phone></Phone> */}
                        <Whatsapp></Whatsapp>
                        <FloatForm></FloatForm>
                        <Slidervirtual slider={recorridoVirtual}/>
                        <Virtual/>
                        <SectionContact></SectionContact>
                    </Route>
                    <Route exact path="/avance-de-obra">
                        <Whatsapp></Whatsapp>
                    <FloatForm></FloatForm>
                        <Sliderobra/>
                        <Obra/>
                    </Route>
                    <Route exact path="/crea-momentos">
                        <Whatsapp></Whatsapp>
                        <FloatForm></FloatForm>
                        <SeccionesSlider slider={secciones}/>
                        <CreaMomentos/>
                    </Route>
                    <Route exact path="/departamentos">
                        {/* <Phone></Phone> */}
                        <FloatForm></FloatForm>
                        <Whatsapp></Whatsapp>
                        <Departamentos inicio={departamentos} />
                    </Route>
                    <Route exact path="/aliados">
                        <FloatForm></FloatForm>
                        <Electrodomesticos></Electrodomesticos>
                    </Route>
                    <Route exact path="/brochure">
                        <Brochure/>
                    </Route>
                    <Route exact path="/contactanos">
                        <Contactanos></Contactanos>
                    </Route>
                    <Route exact path="/gracias">
                        <Thanks/>
                    </Route>
                    <Route exact path="*">
                        <Error404/>
                    </Route>
                </Switch>
                </div>
                <Footer></Footer>
            </Router>
        </div>
    )
}
}
