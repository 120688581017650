
export const fetchProjects = async () => {
    const res = await fetch('https://wordpress.cosapiinmobiliaria.com.pe/wp-json/custom-api/v1/projects', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await res.json();
    return data
};

export const formatDorms = (dorms) => {
	const formatted = dorms.length > 1
		? `${dorms.slice(0, -1).join(', ')} y ${dorms[dorms.length - 1]}`
		: dorms[0];
	return `${formatted} Dorms`;
};
