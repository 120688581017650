import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import "./footer.scss"
export default class Footer extends Component {
    render() {
        return (
            <footer className="Footer b-black" id="footer">
                <div className="all container">
                <MediaQuery query='(min-width:500px)'>
                    <div className="content-logo">
                        <MediaQuery query='(min-width:500px)'>
                            <div className="logo">
                                <img src={require("../../../assets/images/iconos/muvin-footer.svg").default}></img>
                            </div>
                        </MediaQuery>
                    </div>
                </MediaQuery>
                    <div className="footer_contacto flex-column">
                        <div className='c-white d-movil'>
                            <span className='ultra'>Comunícate</span>con nosotros            
                        </div>
                        <div className='content-link-contact'>
                            <p className="contactanos">Contáctanos</p>
                            <a target="__blank" href="tel:+51966 874 541" className="contact flex-row c-white" id="icon-1">
                                <img className='' src={require('../../../assets/images/iconos/call.svg').default} ></img>
                                +51 966 874 541

                            </a>
                            <a className="contact flex-row c-white" id="icon-3" target="__blank" href="mailto:comercial@muvin.pe">
                                <img className='' src={require('../../../assets/images/iconos/message.svg').default} ></img>
                                comercial@muvin.pe
                            </a>
                            <a className="contact flex-row c-white" id="icon-3" target="__blank" href="https://maps.app.goo.gl/kbWUgEziM9XfYuKp7">
                                <img className='' src={require('../../../assets/images/iconos/ubicacion.png')} ></img>
                                Av. Prolongación Iquitos 1870, Lince.
                            </a>
                        </div>
                    </div>
                    <a href="https://www.bestplacetolive.com.pe/" target='_blank' className='flex-column contacto'>
                        <span className='title-brand-best-place c-white'>Certificado por</span>
                        <img className='img-best-place' src={require('../../../assets/images/best_place.svg').default} ></img>
                    </a>
                    <div className="brands">
                        <div className='brand-top'>
                            <div className="content-brand">
                                <a href="https://www.cosapiinmobiliaria.com.pe/" target="_blank" className="d-block cosapi">
                                    <img className='' src={require('../../../assets/images/cosapi-white.png')} ></img>
                                </a>
                            </div>
                            <span className="c-white add">+</span>
                            <div className="content-brand">
                                <a href="https://gerpal.pe/" target="_blank" className="d-block gerpal">
                                    <img className='' src={require('../../../assets/images/gerpal-white.png')} ></img>
                                </a>
                            </div>
                        </div>
                        <div className='brand-down'>
                            <a href="https://adiperu.pe/" target="_blank" className="d-block item">
                                <img className='' src={require('../../../assets/images/adi-peru.png')} ></img>
                            </a>
                            <a href="https://dci.pe/" target="_blank" className="d-block item">
                                <img className='' src={require('../../../assets/images/dci.png')} ></img>
                            </a>
                            <a href="https://asei.com.pe/" target="_blank" className="d-block item asei">
                                <img className='' src={require('../../../assets/images/asei-cosapi.png')} ></img>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-flex content-barra-footer container">
                    <div className='column-1 d-flex'>
                        <div className='item'>
                            <a className='c-white link' href={require("../../../assets/files/TERMINOS-Y-CONDICIONES.pdf")} target='_blank'>Téminos y condiciones</a>
                            <a href={require("../../../assets/files/Politica-de-privacidad.pdf")}  className='c-white link' target='_blank'>Políticas de privacidad de datos</a>
                        </div>
                        <a className="item" href="/libro-de-reclamaciones">
                                <img className="icon" src={require("../../../assets/images/libro-de-reclamacion.jpg")}></img>
                        </a>
                    </div>
                    <MediaQuery query='(max-width:500px)'>
                        <div className="logo-cosapi">
                            <a target="_blank" href="http://adiperu.pe/">
                                <img className="img-3" src={require('../../../assets/images/adi-peru.png')}></img>
                            </a>
                            <a target="_blank" href="https://dci.pe/">
                                <img src={require('../../../assets/images/dci.png')} className='dci'></img>
                            </a>
                            <a href='https://asei.com.pe/'>

                            <img className="img-2 asei" src={require('../../../assets/images/asei-cosapi.png')}></img>
                            </a>
                        </div>
                    </MediaQuery>
                    <MediaQuery query='(max-width:500px)'>
                        <div className='best_place'>
                            <span className='c-white'>Certificado por</span><img className='' src={require('../../../assets/images/best_place.svg').default} ></img>
                        </div>
                    </MediaQuery>
                    <div className='certificado'>
                    </div>
                    <div className="item">
                        <span className='link c-white gotham'>© {new Date().getFullYear()} Proyecto Muvin. Todos los derechos reservados. 
                        <span className="c-white link gotham">
                                Creado con ♡ por <a className="c-white gotham" href="https://www.formulaperu.com/" target="_blank">Fórmula Agencia de Marketing</a>
                        </span></span>                        
                    </div>
                </div>
            </footer>
        )
    }
}

