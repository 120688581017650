import React, { Component, useEffect, useRef, useState } from 'react'
import MediaQuery from 'react-responsive'
import Slider from "react-slick";
import { fetchProjects, formatDorms } from './fetching';
import './banner.scss'
import { projects } from './config';
export const Banner = (props) => {
    const [projectsRelated, setProjects] = useState({
		records: {
			cant: 0,
			limit: 0,
		},
		results: [],
	});
    const ref = useRef();
    const card = [
        
    ]
    const settings ={
        dots: false,
        arrows:false,
        infinite: false,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        // afterChange:this.afterChange
    };

    const fetching = async () => {
		const data = await fetchProjects();
		const filterData = data.results.filter((item, index) => {
			return projects.includes(item.project_name);
		});
		filterData.sort((a, b) => {
			return projects.indexOf(a.project_name) - projects.indexOf(b.project_name);
		});
        console.log(filterData)
		setProjects(prev => ({ ...prev, results: filterData }));
	};
	useEffect(() => {
		fetching();
	}, []);
    
    return (
        <section className="banner">
            <img className='background-point-left' src={require('../../assets/images/Inicio/iconos/background-points.png')} ></img>
            <img className='background-point-right' src={require('../../assets/images/Inicio/iconos/background-lines-right.png')} ></img>
            
            <div className="content-banner">
                
                <div className="title-banner">
                    <p className="paragraph paragraph-1 gotham">
                        ¿Estás interesado en <span className="Third bold">otros proyectos? </span> 
                    </p>
                    <p className="paragraph paragraph-2 gotham">
                        Conoce todos  <span className="bold c-black">los que tenemos</span> 
                    </p>
                </div>

                <div className="content-build">
                    <MediaQuery query='(min-width:500px)'>
                        <div className="content-place">
                            <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/img_pazea.png")}></img><span className="place ultra c-black">Bellavista</span></div>
                            <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render-midgo.png")}></img><span className="place ultra c-black">Lince</span></div>
                            <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render-momen.png")}></img><span className="place ultra c-black">Breña</span></div>
                            <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render_ativa.png")}></img><span className="place ultra c-black">Surco</span></div>
                            <div className="item"><img className="img-fluid" src={require("../../assets/images/new-section/nesta-v2.png")}></img><span className="place ultra c-black">Jesús María</span></div>
                        </div>
                    </MediaQuery>
                    <MediaQuery query='(max-width:499px)'>
                    <div className='content-info-slide projects'>
                        <Slider {...settings} ref={ref}>
                            {
                                projectsRelated?.results &&
							    projectsRelated?.results.map((e,index)=>{
                                    return(
                                        <a href={'https://xn--eldepadetussueos-kub.pe'} key={"card-item"} className={`card-item ${e.project_name}`}>
                                            <div className={`item-brand`}>
                                                <img className='w-full h-full object-contain object-left' src={e?.logo?.url} ></img>
                                            </div>
                                            <div className="content-render">
                                                <img className="render" src={e?.render?.url}></img>
                                                <div className={`fase-content`}>
                                                    <div className={`fase b-${e.project_name}`}>
                                                        {e.project_status}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="background-shadow">
                                                <div className="details">
                                                    <div className="content-detail">
                                                        <div className={`detail-brand `}>
                                                             <img className='w-full h-full object-contain object-left' src={e?.logo?.url} ></img>
                                                        </div>
                                                        <a className="button" href={e.link}>
                                                            <div className={`add b-${e.project_name}`}></div>
                                                        </a>
                                                    </div>
                                                    <div className="content-detail">
                                                        <div className={`district b-${e.project_name}`}>{e.district}</div>
                                                        <div className={`dorms`}>
                                                            {formatDorms(e.dorms)}
                                                        </div>
                                                    </div>
                                                    <div className="content-detail">
                                                        <div className={`content-place`}>
                                                            <span className="title-place" dangerouslySetInnerHTML={{__html: `${e.address}, ${e.district}`}}></span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    </MediaQuery>
                    <div className="content-link">
                        <p className="paragraph paragraph-3 text-center  gotham">
                            Ingresa a  
                        </p>
                        <a href="https://www.eldepadetussueños.pe" target="__blank" className="button btn-submit ultra">
                            www.eldepadetussueños.pe
                        </a>
                        <a href='https://www.cosapiinmobiliaria.com.pe/' target='_blank'>
                            <img className="brand" src={require("../../assets/images/new-section/brand-cosapi.png")}></img>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
   
}
